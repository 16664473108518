import styled from 'styled-components'

export const ButtonsContainerStyles = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  height: auto;
  margin-top: 30px;

  button {
    flex: 0 44%;
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;

    button {
      flex: 0 30%;
    }
  }
`
