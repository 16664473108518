import FeedBackIcon from '../../ui/FeedBackIcon/FeedBackIcon'
import FeedBackIconKO from '../../ui/FeedBackIconKO/FeedBackIconKO'
import FeedBackSeparatorIcon from '../../ui/FeedBackIcon/FeedBackSeperatorIcon'
import {
  FeedBackContainerIconWrapperStyles,
  FeedBackContainerSeparatorStyles,
  FeedBackContainerStyles,
  FeedBackContainerTextStyles
} from './FeedBackContainer.styles'
import { FEEDBACK_CONTENT } from '../../wording/FeedBack.content'

import { useContext } from 'react'
import { FeedBackContext } from '../../context/FeedBackContext'
import { MessagesContext } from '../../context/MessagesContext'
import { feedbackFactory } from '../../libs/feeadback.utils'
import { CookieContext } from '../../context/CookieContext'
import { postFeedBack } from '../../libs/feeadback.utils'

function FeedBackContainer() {
  const { setDisplayFeedback } = useContext(FeedBackContext)
  const { messages } = useContext(MessagesContext)
  const { sessionCookie } = useContext(CookieContext)

  const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_FEEDBACK_SUB_DIRECTORY}`

  const indexOfLastQuestion = messages
    .map((message) => message.isUser)
    .lastIndexOf(true)

  const indexOfLastAnswer = messages
    .map((message) => message.isUser)
    .lastIndexOf(false)

  const onSubmitPositiveFeedBack = async () => {
    setDisplayFeedback(false)
    const payload = feedbackFactory(
      sessionCookie,
      messages[indexOfLastQuestion].content,
      messages[indexOfLastAnswer].content,
      true
    )
    await postFeedBack(url, payload)
  }

  const onSubmitNegativeFeedBack = async () => {
    setDisplayFeedback(false)
    const payload = feedbackFactory(
      sessionCookie,
      messages[indexOfLastQuestion].content,
      messages[indexOfLastAnswer].content,
      false
    )
    await postFeedBack(url, payload)
  }

  return (
    <FeedBackContainerStyles>
      <FeedBackContainerTextStyles>
        {FEEDBACK_CONTENT}
      </FeedBackContainerTextStyles>
      <FeedBackContainerIconWrapperStyles>
        <FeedBackIcon onSubmitPositiveFeedBack={onSubmitPositiveFeedBack} />
        <FeedBackContainerSeparatorStyles>
          <FeedBackSeparatorIcon />
        </FeedBackContainerSeparatorStyles>
        <FeedBackIconKO onSubmitNegativeFeedBack={onSubmitNegativeFeedBack} />
      </FeedBackContainerIconWrapperStyles>
    </FeedBackContainerStyles>
  )
}

export default FeedBackContainer
