import React from 'react'
import {
  AIWelcomeMessageFirstLineMobileStyles,
  AIWelcomeMessageFirstLineStyles,
  AIWelcomeMessageSecondLineMobileStyles,
  AIWelcomeMessageSecondLineStyles,
  AIWelcomeMessageStyles
} from './AIWelcomeMessage.styles'
import {
  AI_WELCOME_MESSAGE_FIRST_LINE,
  AI_WELCOME_MESSAGE_FIRST_LINE_MOBILE,
  AI_WELCOME_MESSAGE_LAST_LINE,
  AI_WELCOME_MESSAGE_SECOND_LINE,
  AI_WELCOME_MESSAGE_SECOND_LINE_MOBILE
} from '../../wording/AIWelcomeMessage.content'
import AIIcon from '../../ui/AIIcon/AIIcon'

function AIWelcomeMessage() {
  return (
    <AIWelcomeMessageStyles>
      <div>
        <AIIcon />
      </div>
      <AIWelcomeMessageFirstLineStyles>
        {AI_WELCOME_MESSAGE_FIRST_LINE}
      </AIWelcomeMessageFirstLineStyles>
      <AIWelcomeMessageSecondLineStyles>
        {AI_WELCOME_MESSAGE_SECOND_LINE} {''}
        <br />
        {AI_WELCOME_MESSAGE_LAST_LINE}
      </AIWelcomeMessageSecondLineStyles>
      {/*
      MOBILE
      */}
      <AIWelcomeMessageFirstLineMobileStyles>
        {AI_WELCOME_MESSAGE_FIRST_LINE_MOBILE}
        <br />
        {AI_WELCOME_MESSAGE_SECOND_LINE_MOBILE}
      </AIWelcomeMessageFirstLineMobileStyles>
      <AIWelcomeMessageSecondLineMobileStyles>
        {AI_WELCOME_MESSAGE_SECOND_LINE} {''}
        <br />
        {AI_WELCOME_MESSAGE_LAST_LINE}
      </AIWelcomeMessageSecondLineMobileStyles>
    </AIWelcomeMessageStyles>
  )
}

export default AIWelcomeMessage
