import React from 'react'
import Layout from './components/Layout/Layout'
import ChatContainer from './components/ChatContainer/ChatContainer'
import GlobalStyles from './theme/GlobalStyles'
import Header from './ui/Header/Header'

function App() {
  return (
    <div className="App">
      <Layout>
        <GlobalStyles />
        <Header />
        <ChatContainer />
      </Layout>
    </div>
  )
}

export default App
