import React, { Key, useContext, useEffect, useState, useRef } from 'react'
import {
  MessageListStyles,
  MessagesContainerStyles
} from './MessagesContainer.styles'
import Message from '../../ui/Message/Message'
import { MessagesContext } from '../../context/MessagesContext'
import { StreamContext } from '../../context/StreamContext'
import ScrollToBottom from '../../ui/ScrollToBottom/ScrollToBottom'
import MessageLoader from '../../ui/MessageLoader/MessageLoader'
import { setNewDate } from '../../libs/dates.utils'
import { chain } from '../../libs/config/stream.config'
import { ConsoleLogger } from '../../libs/logger'
import FeedBackContainer from '../FeedBackContainer/FeedBackContainer'
import { FeedBackContext } from '../../context/FeedBackContext'

function MessagesContainer() {
  const { messages, setMessages } = useContext(MessagesContext)
  const { isCalled, setIsCalled } = useContext(StreamContext)
  const { displayFeedback, setDisplayFeedback } = useContext(FeedBackContext)

  const [streamData, setStreamData] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const div = useRef<null | HTMLDivElement>(null)

  const userQuestion = messages[messages.length - 1]

  const logger = new ConsoleLogger()

  useEffect(() => {
    async function callChat() {
      setDisplayFeedback(false)
      setIsLoading(true)
      const stream = await chain.stream({ question: userQuestion.content })
      const reader = stream.getReader()
      const readChunk = () => {
        reader
          .read()
          .then(({ value, done }) => {
            if (done) {
              setStreamData('')
              setDisplayFeedback(true)
              logger.log('Stream finished')
              return
            }
            if (typeof value === 'string') {
              setDisplayFeedback(false)
              setIsLoading(false)
              setIsCalled(false)
              setStreamData((data) => {
                const updatedData = data + value
                setMessages([
                  ...messages,
                  { content: updatedData, isUser: false, date: setNewDate() }
                ])
                return updatedData
              })
            }
            readChunk()
          })
          .catch((error) => {
            logger.error(error)
          })
      }
      readChunk()
    }
    if (isCalled) {
      callChat()
    }
  }, [isCalled, streamData])
  return (
    <MessagesContainerStyles ref={div}>
      <MessageListStyles>
        {messages.map((message, index: Key) => {
          return (
            <li key={index}>
              <Message message={message} />
            </li>
          )
        })}
        {displayFeedback && !isLoading && (
          <li>
            <FeedBackContainer />
          </li>
        )}
      </MessageListStyles>
      {isLoading && <MessageLoader text={'...'} />}
      <ScrollToBottom />
    </MessagesContainerStyles>
  )
}

export default MessagesContainer
