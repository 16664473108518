export function setNewDate(): Date {
  return new Date()
}

export function formatDate(date: Date): string {
  const hours = date.getHours()
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const formattedDate = `${date.toDateString()}, ${hours}:${minutes}`
  const todayDate = `Today, ${hours}:${minutes}`

  return isToday(date) ? todayDate : formattedDate
}

export function convertUnixTimestampToDate(timestamp: number): Date {
  return new Date(timestamp * 1000)
}

function isToday(date: Date): boolean {
  const today = new Date().getDay()

  return today === date.getDay()
}
