import styled from 'styled-components'

export const LayoutContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: var(--bg-color);
`
