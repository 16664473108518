function FeedBackSeparatorIcon() {
  return (
    <svg
      width="2"
      height="20"
      viewBox="0 0 2 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.120117" width="1" height="20" fill="#544E85" />
    </svg>
  )
}

export default FeedBackSeparatorIcon
