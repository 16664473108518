/* eslint-disable no-console */
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import { IMessage } from '../domain/message'
import { setNewDate } from '../libs/dates.utils'
import { AI_FIRST_QUESTION } from '../wording/MessagesContext.content'
import { ContextualQuestionsContext } from './DisplayContextualQuestionsContext'
import { CookieContext } from './CookieContext'
import { useFetchChatHistory } from '../libs/hooks/useFetchChatHistory'
import { AppLoaderContext } from './AppLoaderContext'

interface MessageContext {
  messages: IMessage[]
  setMessages: Dispatch<SetStateAction<IMessage[]>>
}

const initialValue = [
  {
    content: AI_FIRST_QUESTION,
    isUser: false,
    date: setNewDate()
  }
]
export const MessagesContext = createContext<MessageContext>({
  messages: initialValue,
  setMessages: () => null
})

const { Provider } = MessagesContext

function MessagesProvider({ children }: { children: React.ReactNode }) {
  const { setContextualQuestionsIsVisible } = useContext(
    ContextualQuestionsContext
  )
  const { setDisplayLoader } = useContext(AppLoaderContext)
  const { sessionCookie } = useContext(CookieContext)

  const [messages, setMessages] = useState(initialValue)
  const { chatHistory } = useFetchChatHistory()

  useEffect(() => {
    setDisplayLoader(true)
    setContextualQuestionsIsVisible(false)
    if (sessionCookie && chatHistory && chatHistory.length > 0) {
      if (chatHistory.length !== messages.length - 1) {
        setMessages(messages.concat(chatHistory))
        setContextualQuestionsIsVisible(false)
      }
      setContextualQuestionsIsVisible(false)
      setDisplayLoader(false)
    }

    if (sessionCookie && chatHistory && chatHistory.length === 0) {
      setContextualQuestionsIsVisible(true)
      setDisplayLoader(false)
    }
  }, [sessionCookie, chatHistory])

  return <Provider value={{ messages, setMessages }}>{children}</Provider>
}

export default MessagesProvider
