import {
  UserMessageStyles,
  AIMessageStyles,
  MessageWrapperStyles
} from './Message.styles'
import { IMessage } from '../../domain/message'
import Markdown from 'react-markdown'

interface MessagesProps {
  message: IMessage
}

function Message({ message }: MessagesProps) {
  return (
    <MessageWrapperStyles $isUser={message.isUser}>
      {message.isUser && (
        <UserMessageStyles $isUser={message.isUser}>
          {message.content}
        </UserMessageStyles>
      )}
      {!message.isUser && (
        <AIMessageStyles>
          <Markdown>{message.content}</Markdown>
        </AIMessageStyles>
      )}
    </MessageWrapperStyles>
  )
}

export default Message
