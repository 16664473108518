import { useState, useEffect } from 'react'
import {
  LoaderWriterSectionStyles,
  LoaderWriterStyles
} from './LoaderWriter.styles'
interface MessageLoaderProps {
  text: string
  delay: number
  infinite: boolean
}

const LoaderWriter = ({ text, delay, infinite }: MessageLoaderProps) => {
  const [currentText, setCurrentText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    let timeout: string | number | NodeJS.Timeout | undefined

    if (currentIndex <= text.length) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex])
        setCurrentIndex((prevIndex) => prevIndex + 1)
      }, delay)
    } else if (infinite) {
      setCurrentIndex(0)
      setCurrentText('')
    }

    return () => clearTimeout(timeout)
  }, [currentIndex, delay, infinite, text])

  return (
    <LoaderWriterSectionStyles>
      <LoaderWriterStyles role="alert">{currentText}</LoaderWriterStyles>
    </LoaderWriterSectionStyles>
  )
}

export default LoaderWriter
