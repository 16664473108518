import styled from 'styled-components'

export const SendButtonStyles = styled.button`
  //height: 2.5rem;
  //width: 2.912rem;
  height: 40px;
  width: 46.592px;
  color: var(--fonts-color-contextual-question);
  border: 1px solid var(--btn-border-color);
  border-radius: 4.75rem;
  background-color: #f2d166;
  margin: 0.3rem;

  img {
    display: flex;
    margin: auto;
  }

  &:hover {
    background-color: var(--btn-hover-color);
  }
  &:focus {
    background-color: var(--btn-hover-color);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--bg-color);
    border: none;
  }

  @media screen and (max-width: 575px) {
    width: 6.2rem;
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 2.912rem;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    width: 2.5rem;
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    width: 2.912rem;
  }
`
