import { createContext, Dispatch, SetStateAction, useState } from 'react'

interface StreamContext {
  isCalled: boolean
  setIsCalled: Dispatch<SetStateAction<boolean>>
}
export const StreamContext = createContext<StreamContext>({
  isCalled: false,
  setIsCalled: () => null
})

const { Provider } = StreamContext

function StreamProvider({ children }: { children: React.ReactNode }) {
  const [isCalled, setIsCalled] = useState(false)

  return <Provider value={{ isCalled, setIsCalled }}>{children}</Provider>
}

export default StreamProvider
