import { AppLoader } from './Loader.styles'

function Loader() {
  return (
    <AppLoader
      aria-live="polite"
      aria-label="Loading, please do not refresh the page"
      role="alert"
    >
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.2571 9.03799C40.2024 6.11965 34.326 4.95075 28.5392 5.71259C22.7524 6.47443 17.3788 9.12444 13.2516 13.2516C9.12444 17.3788 6.47443 22.7525 5.71259 28.5392C4.95075 34.326 6.11965 40.2024 9.038 45.2571C11.9563 50.3119 16.461 54.2624 21.8535 56.496C27.2459 58.7296 33.2246 59.1215 38.8624 57.6108C44.5002 56.1002 49.482 52.7714 53.0352 48.1409C56.5883 43.5103 58.5143 37.8367 58.5143 32C58.5143 30.4852 59.7423 29.2572 61.2571 29.2572C62.772 29.2572 64 30.4852 64 32C64 39.0443 61.6756 45.8918 57.3873 51.4804C53.099 57.069 47.0865 61.0864 40.2822 62.9096C33.478 64.7328 26.2622 64.2599 19.7542 61.5641C13.2461 58.8684 7.80937 54.1005 4.28723 48C0.765085 41.8995 -0.645655 34.8072 0.273809 27.8232C1.19327 20.8392 4.39155 14.3537 9.37262 9.37262C14.3537 4.39156 20.8392 1.19327 27.8232 0.27381C34.8072 -0.645655 41.8995 0.765084 48 4.28723C49.3119 5.04465 49.7614 6.72215 49.004 8.03404C48.2465 9.34593 46.569 9.79541 45.2571 9.03799Z"
          fill="#068484"
        />
      </svg>
    </AppLoader>
  )
}

export default Loader
