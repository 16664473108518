import styled from 'styled-components'

export const FeedBackIconOKtyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: var(--primary-color);

    &:hover {
      fill: var(--btn-hover-color);
      cursor: pointer;
    }

    &:focus {
      fill: var(--btn-hover-color);
    }
  }
`
