import React from 'react'
import { LayoutContainer } from './Layout.styles'

interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <LayoutContainer>
      <>{children}</>
    </LayoutContainer>
  )
}
export default Layout
