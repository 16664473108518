import { useContext, useEffect, useState } from 'react'
import { IMessage } from '../../domain/message'
import { MessageFromHistoryAPI } from '../../domain/messageFromHistoryAPI'
import { CHAT_HISTORY_URL } from '../config/history.config'
import { ConsoleLogger } from '../logger'
import { CookieContext } from '../../context/CookieContext'
import useSWR from 'swr'
import { messageListMapper } from '../message.mapper'
import { AppLoaderContext } from '../../context/AppLoaderContext'
import { ContextualQuestionsContext } from '../../context/DisplayContextualQuestionsContext'

interface ApiResponse {
  data: MessageFromHistoryAPI[]
  isLoading: boolean
  error: Error | undefined
}

const fetcher = (url: string) =>
  fetch(url, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json())

export const useFetchChatHistory = () => {
  const { sessionCookie } = useContext(CookieContext)
  const { setContextualQuestionsIsVisible } = useContext(
    ContextualQuestionsContext
  )
  const { setDisplayLoader } = useContext(AppLoaderContext)

  const [chatHistory, setChatHistory] = useState<IMessage[]>([])
  const [mounted, setMounted] = useState(false)

  const url: string = `${CHAT_HISTORY_URL}/${sessionCookie}`

  const { data, isLoading, error }: ApiResponse = useSWR(
    mounted ? url : null,
    fetcher
  )

  const logger = new ConsoleLogger()

  useEffect(() => {
    setDisplayLoader(true)
    setContextualQuestionsIsVisible(false)
    if (sessionCookie) {
      setMounted(true)
      if (data) {
        logger.log('Fetching chat history')
        const messageList = messageListMapper(data)
        setChatHistory(messageList)
        if (data.length > 0) {
          setContextualQuestionsIsVisible(false)
        }
        setContextualQuestionsIsVisible(true)
        setDisplayLoader(false)
      }
    }
  }, [sessionCookie, data, isLoading])

  return { isLoading, chatHistory, error }
}
