import React from 'react'
import { MessageStyles, MessageWrapperStyles } from './MessageLoader.styles'
import LoaderWriter from '../LoaderWriter/LoaderWriter'

interface MessageLoaderProps {
  text: string
}

function MessageLoader({ text }: MessageLoaderProps) {
  return (
    <MessageWrapperStyles>
      <MessageStyles>
        <LoaderWriter text={text} delay={500} infinite />
      </MessageStyles>
    </MessageWrapperStyles>
  )
}
export default MessageLoader
