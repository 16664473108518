import { SendButtonStyles } from './SendButton.styles'
import SendIcon from '../../assets/send.svg'
import SendDisabledIcon from '../../assets/send-disabled.svg'

interface ButtonProps {
  value: string
  tabIndex: number
  disabled: boolean
  onClick?: () => void
}

function SendButton({ value, tabIndex, disabled, onClick }: ButtonProps) {
  return (
    <SendButtonStyles
      type="submit"
      value={value}
      tabIndex={tabIndex}
      disabled={disabled}
      onClick={onClick}
      data-testid="sendButton"
      aria-label="Send message"
    >
      <img src={disabled ? SendDisabledIcon : SendIcon} alt={value} />
    </SendButtonStyles>
  )
}

export default SendButton
