import { FeedBackIconKOStyles } from './FeedBackIconKO.styles'

interface FeedBackIconProps {
  onSubmitNegativeFeedBack: () => void
}

function FeedBackIconKO({ onSubmitNegativeFeedBack }: FeedBackIconProps) {
  return (
    <FeedBackIconKOStyles>
      <svg
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="button"
        aria-label="feedback not useful button"
        onClick={onSubmitNegativeFeedBack}
      >
        <path d="M16.4932 5.41353C16.9213 5.41354 17.2995 5.57443 17.6277 5.89621C17.956 6.21798 18.1201 6.58868 18.1201 7.0083L18.1201 8.43338C18.1201 8.52597 18.108 8.62518 18.0838 8.73102C18.0596 8.83684 18.0324 8.93556 18.0024 9.02717L15.4232 14.9932C15.2942 15.2753 15.078 15.5136 14.7746 15.7082C14.4711 15.9027 14.1545 16 13.8246 16L4.36051 16L4.36051 5.41353L9.5961 0.325592C9.77494 0.150283 9.98206 0.0456679 10.2174 0.0117467C10.4528 -0.0221889 10.6784 0.0173925 10.8941 0.130492C11.1099 0.243591 11.268 0.40419 11.3684 0.612289C11.4688 0.820402 11.4901 1.03586 11.4324 1.25866L10.4632 5.41353L16.4932 5.41353ZM5.71049 5.97678L5.71049 14.6767L13.8278 14.6767C13.8913 14.6767 13.9562 14.6597 14.0225 14.6258C14.0889 14.5919 14.1394 14.5353 14.174 14.4561L16.7702 8.50124L16.7702 7.0083C16.7702 6.92913 16.7442 6.86409 16.6923 6.81318C16.6403 6.76228 16.574 6.73683 16.4932 6.73683L10.0169 6.73683C9.37282 6.73683 8.8967 6.1368 9.04309 5.50955L9.88513 1.90164L5.71049 5.97678ZM1.74705 16C1.29965 16 0.916643 15.8438 0.598042 15.5315C0.279425 15.2192 0.120117 14.8438 0.120117 14.4052L0.120118 7.0083C0.120118 6.56974 0.279426 6.19431 0.598042 5.88201C0.916644 5.56969 1.29965 5.41353 1.74705 5.41353L4.36051 5.41353L4.36051 6.73683L1.74705 6.73683C1.66628 6.73683 1.59992 6.76228 1.548 6.81318C1.49607 6.86408 1.4701 6.92912 1.4701 7.0083L1.4701 14.4052C1.4701 14.4844 1.49607 14.5494 1.54799 14.6004C1.59992 14.6513 1.66628 14.6767 1.74705 14.6767L4.36051 14.6767L4.36051 16L1.74705 16Z" />
      </svg>
    </FeedBackIconKOStyles>
  )
}

export default FeedBackIconKO
