export const contextualQuestions = [
  {
    content:
      'I have not received my points following a stay, what should I do?',
    isUser: true
  },
  {
    content: 'How do I update my personal details in my account?',
    isUser: true
  },
  {
    content: 'What is the best price guarantee?',
    isUser: true
  },
  {
    content: 'I have not received my invoice',
    isUser: true
  }
]
