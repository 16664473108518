import styled from 'styled-components'

interface MessageHeaderProps {
  $isUser: boolean
}

export const MessageWrapperStyles = styled.div<MessageHeaderProps>`
  display: flex;
  margin-bottom: 50px;
  color: var(--primary-color);
  justify-content: ${(props) => (props.$isUser ? 'flex-end' : 'flex-start')};
`

export const UserMessageStyles = styled.div<MessageHeaderProps>`
  margin: 0;
  max-width: 80%;
  width: fit-content;
  text-align: left;
  padding: var(--user-question-padding);
  line-height: var(--fonts-line-height-message);
  font-size: var(--fonts-size-message);
  font-weight: var(--fonts-weight-message);
  color: var(--primary-color);
  border-radius: var(--messages-border-radius);
  background-color: var(--user-question-background-color);

  @media only screen and (max-width: 991px) {
    text-align: left;
    max-width: 60%;
    width: fit-content;
    font-size: var(--fonts-size-message-mobile);
    line-height: var(--fonts-line-height-message-mobile);
    padding: var(--user-question-padding);
  }
`
export const AIMessageStyles = styled.div`
  margin: 0;
  max-width: 80%;
  width: fit-content;
  text-align: left;
  padding: 0 1rem;
  line-height: var(--fonts-line-height-message);
  font-size: var(--fonts-size-message);
  font-weight: var(--fonts-weight-message);
  color: var(--primary-color);
  background-color: var(--ai-response-background-color);
  border-radius: var(--messages-border-radius);
  word-wrap: break-word;

  p {
    text-align: left;
  }

  a {
    color: var(--link-color);

    &:hover {
      color: var(--btn-hover-color);
    }
    &:focus {
      color: var(--btn-hover-color);
    }
  }

  @media only screen and (max-width: 991px) {
    max-width: 80%;
    text-align: left;
    font-size: var(--fonts-size-message-mobile);
    line-height: var(--fonts-line-height-message-mobile);
  }
`
