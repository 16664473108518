import React, { useContext, useState } from 'react'
import { UserInputForm } from './InputContainer.styles'
import TextArea from '../../ui/TextArea/TextArea'
import { MessagesContext } from '../../context/MessagesContext'
import { ContextualQuestionsContext } from '../../context/DisplayContextualQuestionsContext'
import { StreamContext } from '../../context/StreamContext'
import SendButton from '../../ui/SendButton/SendButton'
import { setNewDate } from '../../libs/dates.utils'

const MAX_NUMBER_ROWS_TEXTAREA = 5
const KEY_ENTER = 13

function InputContainer() {
  const { messages, setMessages } = useContext(MessagesContext)
  const { setContextualQuestionsIsVisible } = useContext(
    ContextualQuestionsContext
  )
  const { setIsCalled } = useContext(StreamContext)
  const [disabled, setDisabled] = useState(true)
  const [textAreaRowsNumber, setTextAreaRowsNumber] = useState<number>(1)
  const [textAreaValue, setTextAreaValue] = useState<string>('')

  const handleSubmit = (event: any) => {
    event.preventDefault()

    const question: string = event.target.userQuestion.value
    submitMessage(question)

    event.target.reset()
  }

  const handleKeyDown = (event: any) => {
    if (
      event.keyCode === KEY_ENTER &&
      !event.shiftKey &&
      textAreaValue !== ''
    ) {
      event.preventDefault()
      submitMessage(textAreaValue)
    } else if (
      event.keyCode === KEY_ENTER &&
      event.shiftKey &&
      textAreaRowsNumber < MAX_NUMBER_ROWS_TEXTAREA
    ) {
      setTextAreaRowsNumber(textAreaRowsNumber + 1)
    }
  }

  const onChange = (event: any) => {
    if (event.target.value) {
      setTextAreaValue(event.target.value)
      setDisabled(false)
    } else if (event.target.value === '') {
      setTextAreaValue('')
      setDisabled(true)
    } else {
      setDisabled(true)
    }
  }

  const submitMessage = (question: string) => {
    setMessages([
      ...messages,
      { content: question, isUser: true, date: setNewDate() }
    ])
    setContextualQuestionsIsVisible(false)
    setIsCalled(true)
    setTextAreaValue('')
    setDisabled(true)
    setTextAreaRowsNumber(1)
  }

  return (
    <UserInputForm
      onSubmit={handleSubmit}
      data-testid="form"
      name="user question"
    >
      <TextArea
        value={textAreaValue}
        rowsNumber={textAreaRowsNumber}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
      <SendButton value={'send'} tabIndex={8} disabled={disabled} />
    </UserInputForm>
  )
}

export default InputContainer
