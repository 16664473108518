import React, { Key, useContext } from 'react'
import { ButtonsContainerStyles } from './ButtonsContainer.styles'
import ContextualButton from '../../ui/ContextualButton/ContextualButton'
import { MessagesContext } from '../../context/MessagesContext'
import { ContextualQuestionsContext } from '../../context/DisplayContextualQuestionsContext'
import { StreamContext } from '../../context/StreamContext'
import { setNewDate } from '../../libs/dates.utils'
import { ContextualQuestions } from '../../domain/contextualQuestions'

interface ButtonsContainerProps {
  contextualQuestionsList: ContextualQuestions[]
}

function ButtonsContainer({ contextualQuestionsList }: ButtonsContainerProps) {
  const { messages, setMessages } = useContext(MessagesContext)
  const { setContextualQuestionsIsVisible } = useContext(
    ContextualQuestionsContext
  )
  const { setIsCalled } = useContext(StreamContext)

  const handleClick = (contextualQuestion: ContextualQuestions) => {
    setMessages([...messages, { ...contextualQuestion, date: setNewDate() }])
    setContextualQuestionsIsVisible(false)
    setIsCalled(true)
  }

  return (
    <ButtonsContainerStyles role="list">
      {contextualQuestionsList.map(
        (contextualQuestion: ContextualQuestions, index: Key) => {
          return (
            <ContextualButton
              text={contextualQuestion}
              tabIndex={Number(index) + 2}
              onClick={() => handleClick(contextualQuestion)}
              value={contextualQuestion.content}
              key={index}
            />
          )
        }
      )}
    </ButtonsContainerStyles>
  )
}

export default ButtonsContainer
