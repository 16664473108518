import styled from 'styled-components'

export const LoaderWriterSectionStyles = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 50px;
  color: #ffffff;
  border-radius: 8px;
  background-color: #1e1852;
`

export const LoaderWriterStyles = styled.span`
  font-size: 1rem;
`
