import { IMessage } from '../domain/message'
import { MessageFromHistoryAPI } from '../domain/messageFromHistoryAPI'
import { convertUnixTimestampToDate } from './dates.utils'

export function convertMessageFromHistory(
  message: MessageFromHistoryAPI
): IMessage {
  const date = convertUnixTimestampToDate(message.date)

  return {
    ...message,
    date: date
  }
}

export function messageListMapper(
  messageList: MessageFromHistoryAPI[]
): IMessage[] {
  return messageList.map((message) => convertMessageFromHistory(message))
}
