import Logo from '../Logo/Logo'
import { HeaderStyles } from './Header.styles'

export default function Header() {
  return (
    <HeaderStyles role="heading">
      <a
        href="https://help.accor.com/s/?language=en_US"
        title="Accor Gen AI FAQ"
      >
        <Logo />
      </a>
    </HeaderStyles>
  )
}
