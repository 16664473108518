import React, { createContext, Dispatch, SetStateAction, useState } from 'react'

interface ContextualQuestionsContext {
  contextualQuestionsIsVisible: boolean
  setContextualQuestionsIsVisible: Dispatch<SetStateAction<boolean>>
}

const initialValue = false
export const ContextualQuestionsContext =
  createContext<ContextualQuestionsContext>({
    contextualQuestionsIsVisible: false,
    setContextualQuestionsIsVisible: () => null
  })

const { Provider } = ContextualQuestionsContext

function DisplayContextualQuestionsProvider({
  children
}: {
  children: React.ReactNode
}) {
  const [contextualQuestionsIsVisible, setContextualQuestionsIsVisible] =
    useState(initialValue)

  return (
    <Provider
      value={{ contextualQuestionsIsVisible, setContextualQuestionsIsVisible }}
    >
      {children}
    </Provider>
  )
}

export default DisplayContextualQuestionsProvider
