import styled from 'styled-components'

export const HeaderStyles = styled.header`
  position: fixed;
  top: 0px;
  height: auto;
  width: 100vw;
  padding: 1.875rem 0;

  text-align: center;
  background-color: rgba(5, 0, 51, 0.8);

  @media only screen and (max-width: 991px) {
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 1.5rem 0;
  }
`
