import { ButtonSyles } from './ContextualButton.styles'
import { ContextualQuestions } from '../../domain/contextualQuestions'

interface ButtonProps {
  text: ContextualQuestions
  tabIndex: number
  onClick?: () => void
  value?: string
}

function ContextualButton({ text, tabIndex, onClick, value }: ButtonProps) {
  return (
    <ButtonSyles
      tabIndex={tabIndex}
      onClick={onClick}
      value={value}
      aria-label={text.content}
      role="listitem"
      type="button"
    >
      {text.content}
    </ButtonSyles>
  )
}

export default ContextualButton
