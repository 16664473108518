function AIIcon() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <path
        d="M36.9646 35.3213H30.2693L25.3459 24.7822C23.838 25.5904 22.6932 26.992 21.4979 28.4557C20.5626 29.6008 19.5963 30.7839 18.4008 31.7504C17.0094 32.8747 15.3921 33.8701 13.3704 34.5494C12.4547 34.8539 10.8968 35.2521 10.3141 35.3106C10.0644 35.3341 9.89789 35.3223 9.86222 35.2404C9.83843 35.1701 9.89789 35.1232 10.1595 34.9944C10.2124 34.9694 10.2947 34.9329 10.3986 34.8868C10.8785 34.6739 11.8194 34.2565 12.4547 33.8233C13.418 33.1558 13.965 32.4414 14.0126 31.891C13.9641 31.7214 13.8594 31.5132 13.7335 31.2626C13.1807 30.1628 12.2176 28.2465 13.7866 25.2038C14.0639 24.6625 14.3257 24.1837 14.5676 23.7414C14.9275 23.0832 15.2431 22.5061 15.4991 21.9247C15.9867 20.8121 16.3435 19.2428 16.4624 18.0717C16.4743 18.0014 16.4862 18.0014 16.5219 18.0483C17.4613 18.9852 21.1004 22.6625 20.5652 26.3281C21.7782 25.8714 23.8712 24.4778 24.8345 23.7399C25.0608 23.5663 25.2697 23.3915 25.4695 23.2243C26.1724 22.6361 26.7627 22.142 27.6054 22.1238C28.1467 22.1172 28.4101 22.2586 28.6742 22.4003C28.8801 22.5108 29.0863 22.6215 29.4249 22.6625C29.6152 22.6859 29.9006 22.6508 30.0433 22.5805C30.1028 22.5571 30.0909 22.4868 29.9957 22.4634C29.5603 22.3611 29.2607 22.1553 28.9617 21.95C28.5421 21.6618 28.1235 21.3743 27.3319 21.3743C26.4193 21.3743 25.6413 21.9974 25.0131 22.5006C24.8063 22.6662 24.6158 22.8188 24.4421 22.9319L21.0052 15.8817C19.5782 12.9305 20.0063 10.7873 23.1696 6.57129L36.9646 35.3213Z"
        fill="#050033"
      />
    </svg>
  )
}

export default AIIcon
