import styled from 'styled-components'

export const FeedBackContainerStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 225px;
  height: 46px;
  color: var(--primary-color);
`

export const FeedBackContainerTextStyles = styled.p`
  width: auto;
  color: var(--primary-color);
`

export const FeedBackContainerIconWrapperStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: auto;
  height: 100%;
  padding: 0 6px;
  border: 1px solid var(--btn-border-color);
  border-radius: 16px;
`

export const FeedBackContainerSeparatorStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
