import styled from 'styled-components'

export const UserInputForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 90%;
  max-height: 200px;
  margin-top: 30px;
  margin-bottom: 15px;
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
`
