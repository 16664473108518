import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import AppLoaderContextProvider from './context/AppLoaderContext'
import DisplayContextualQuestionsProvider from './context/DisplayContextualQuestionsContext'
import StreamProvider from './context/StreamContext'
import CookieProvider from './context/CookieContext'
import FeedBackContextProvider from './context/FeedBackContext'
import MessagesProvider from './context/MessagesContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AppLoaderContextProvider>
      <DisplayContextualQuestionsProvider>
        <CookieProvider>
          <MessagesProvider>
            <FeedBackContextProvider>
              <StreamProvider>
                <App />
              </StreamProvider>
            </FeedBackContextProvider>
          </MessagesProvider>
        </CookieProvider>
      </DisplayContextualQuestionsProvider>
    </AppLoaderContextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
