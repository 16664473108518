import { FeedBack } from '../domain/feedback'

export async function postFeedBack(url: string, payload: FeedBack) {
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
}

export const feedbackFactory = (
  session_id: string,
  question: string,
  answer: string,
  useful: boolean
) => {
  return {
    session_id: session_id,
    question: question,
    answer: answer,
    useful: useful
  }
}
