import styled from 'styled-components'

export const TextAreaStyles = styled.textarea`
  padding: 1rem;
  font-size: 1rem;
  line-height: var(--fonts-line-height-message);
  color: var(--primary-color);
  background-color: var(--bg-color);
  border-radius: 8px;

  &::placeholder {
    opacity: 0.7;
    font-family: 'Roboto', sans-serif;
    color: var(--primary-color);
  }
`

export const Label = styled.label`
  display: none;
`
