import styled from 'styled-components'

export const MessageWrapperStyles = styled.div`
  margin-bottom: 50px;
  color: var(--primary-color);
`

export const MessageStyles = styled.div`
  text-align: left;
  line-height: var(--fonts-line-height-message);
  font-size: var(--fonts-size-message);
  font-weight: var(--fonts-weight-message);
  color: var(--primary-color);
  margin: 0;

  @media only screen and (max-width: 991px) {
    font-size: var(--fonts-size-message-mobile);
    line-height: var(--fonts-line-height-message-mobile);
  }
`
