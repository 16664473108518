import styled from 'styled-components'

export const ButtonSyles = styled.button`
  margin-top: 10px;
  width: 392px;
  height: 60px;
  text-align: left;
  font-size: var(--fonts-size-contextual-question);
  font-weight: var(--fonts-weight-contextual-question);
  line-height: var(--fonts-line-height-contextual-question);
  color: rgba(255, 255, 255, 0.6);
  padding: 16px;
  border: 1px solid var(--btn-border-color);
  border-radius: 6px;
  background-color: var(--bg-color);

  &:hover {
    background-color: var(--btn-hover-color);
  }
  &:focus {
    background-color: var(--btn-hover-color);
  }

  @media screen and (max-width: 575px) {
    width: 90%;
    font-size: var(--fonts-size-contextual-question-mobile);
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    width: 50%;
    font-size: var(--fonts-size-contextual-question-mobile);
  }
`
