import { TEXTAREA_PLACEHOLDER_TEXT } from '../../wording/TextArea.content'
import { Label, TextAreaStyles } from './TextArea.styles'

interface TextAreaProps {
  value: string
  rowsNumber: number
  onChange: any
  onKeyDown: any
}

function TextArea({ value, rowsNumber, onChange, onKeyDown }: TextAreaProps) {
  const inputLabel = TEXTAREA_PLACEHOLDER_TEXT
  return (
    <>
      <Label htmlFor={'userQuestion'}>{inputLabel}</Label>
      <TextAreaStyles
        value={value}
        rows={rowsNumber}
        cols={70}
        id="userQuestion"
        name="user question"
        data-testid="user-question"
        placeholder={inputLabel}
        tabIndex={7}
        onChange={onChange}
        onKeyDown={onKeyDown}
      ></TextAreaStyles>
    </>
  )
}

export default TextArea
