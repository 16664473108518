import styled from 'styled-components'

export const MessagesContainerStyles = styled.article`
  width: 90%;
  height: 100%;
  padding-top: 204px;
  overflow: auto;

  @media only screen and (max-width: 991px) {
    padding-top: 280px;
  }
`

export const MessageListStyles = styled.ul`
  margin: 0;
  padding: 0;
`
