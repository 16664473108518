import React, { useContext } from 'react'
import {
  AIWelcomeMessageWrapperStyles,
  ChatContainerStyles,
  LoaderWrapperStyles
} from './ChatContainer.styles'
import ButtonsContainer from '../BouttonsContainer/ButtonsContainer'
import InputContainer from '../InputContainer/InputContainer'
import { MessagesContext } from '../../context/MessagesContext'
import { ContextualQuestionsContext } from '../../context/DisplayContextualQuestionsContext'
import { contextualQuestions } from '../../wording/ContextualQuestions.content'
import Loader from '../../ui/Loader/Loader'
import MessagesContainer from '../MessagesContainer/MessagesContainer'
import { AppLoaderContext } from '../../context/AppLoaderContext'
import AIWelcomeMessage from '../AIWelcomeMessage/AIWelcomeMessage'

function ChatContainer() {
  const { contextualQuestionsIsVisible } = useContext(
    ContextualQuestionsContext
  )
  const { displayLoader } = useContext(AppLoaderContext)

  const { messages } = useContext(MessagesContext)

  return (
    <ChatContainerStyles>
      {displayLoader && (
        <LoaderWrapperStyles>
          <Loader />
        </LoaderWrapperStyles>
      )}
      {!displayLoader && contextualQuestionsIsVisible && (
        <AIWelcomeMessageWrapperStyles>
          <AIWelcomeMessage />
        </AIWelcomeMessageWrapperStyles>
      )}
      {!displayLoader && !contextualQuestionsIsVisible && <MessagesContainer />}
      {contextualQuestionsIsVisible &&
        messages.length === 1 &&
        !displayLoader && (
          <ButtonsContainer contextualQuestionsList={contextualQuestions} />
        )}
      <InputContainer />
    </ChatContainerStyles>
  )
}

export default ChatContainer
