import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from 'react'
import { cookiesService } from '../libs/cookies.utils'

interface CookieContext {
  sessionCookie: string
  setSessionCookie: Dispatch<SetStateAction<string>>
}

export const CookieContext = createContext<CookieContext>({
  sessionCookie: '',
  setSessionCookie: () => null
})

const { Provider } = CookieContext

function CookieProvider({ children }: { children: React.ReactNode }) {
  const [sessionCookie, setSessionCookie] = useState('')
  const sessionId = cookiesService()

  useEffect(() => {
    if (sessionId) {
      setSessionCookie(sessionId)
    }
  }, [sessionId])

  return (
    <Provider value={{ sessionCookie, setSessionCookie }}>{children}</Provider>
  )
}

export default CookieProvider
