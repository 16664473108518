import { RunnableConfig } from 'langchain/runnables'
import { RemoteRunnable } from 'langchain/runnables/remote'
import { cookiesService } from '../cookies.utils'

const url = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_LOCAL_SUB_DIRECTORY}/`
const config: RunnableConfig = {
  configurable: { session_id: cookiesService() }
}

export const chain = new RemoteRunnable({
  url: url,
  options: {
    timeout: 30000
  }
}).withConfig(config)
