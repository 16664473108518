import styled from 'styled-components'

export const ChatContainerStyles = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 100%;
  background-color: var(--bg-color);

  @media only screen and (max-width: 991px) {
    width: 100vw;
  }
`
export const LoaderWrapperStyles = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 991px) {
    width: 100vw;
  }
`

export const AIWelcomeMessageWrapperStyles = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70%;
`
