import styled from 'styled-components'

export const AIWelcomeMessageStyles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 280px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--primary-color);
`

export const AIWelcomeMessageFirstLineStyles = styled.div`
  margin-top: 30px;
  font-size: var(--ai-welcome-message-first-line-font-size);
  line-height: var(--ai-welcome-message-first-line-line-height);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;

  @media screen and (max-width: 789px) {
    display: none;
  }
`

export const AIWelcomeMessageSecondLineStyles = styled.div`
  padding: 0.5rem;
  font-size: var(--ai-welcome-message-second-and-last-line-font-size);
  line-height: var(--ai-welcome-message-second-and-last-line-font-height);
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;

  @media screen and (max-width: 789px) {
    display: none;
  }
`
//Mobile
export const AIWelcomeMessageFirstLineMobileStyles = styled.div`
  @media screen and (min-width: 360px) and (max-width: 788px) {
    font-size: 20px;
    margin-top: 30px;
    line-height: var(--ai-welcome-message-first-line-line-height);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  @media screen and (min-width: 789px) {
    display: none;
  }
`
export const AIWelcomeMessageSecondLineMobileStyles = styled.div`
  @media screen and (min-width: 360px) and (max-width: 788px) {
    font-size: var(--ai-welcome-message-second-and-last-line-font-size);
    line-height: var(--ai-welcome-message-second-and-last-line-font-height);
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
  }
  @media screen and (min-width: 789px) {
    display: none;
  }
`
