import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

const COOKIE_NAME = 'AllAISupport'
const NEW_COOKIE_VALUE = uuidv4()

const uidPattern: RegExp =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

export function setNewCookie() {
  Cookies.set(COOKIE_NAME, NEW_COOKIE_VALUE, { expires: 7, path: '' })
}

export function getCookie() {
  const sessionId = Cookies.get(COOKIE_NAME)

  if (sessionId && !uidPattern.test(sessionId)) {
    const error = new Error('UID is not a well formated!')

    return error.message
  }

  return sessionId
}

export function cookiesService() {
  const sessionId = getCookie()

  if (!sessionId) {
    setNewCookie()
    return getCookie()
  }

  return sessionId
}
