import { createContext, Dispatch, SetStateAction, useState } from 'react'

interface AppLoaderContext {
  displayLoader: boolean
  setDisplayLoader: Dispatch<SetStateAction<boolean>>
}
export const AppLoaderContext = createContext<AppLoaderContext>({
  displayLoader: true,
  setDisplayLoader: () => null
})

const { Provider } = AppLoaderContext

function AppLoaderContextProvider({ children }: { children: React.ReactNode }) {
  const [displayLoader, setDisplayLoader] = useState(true)

  return (
    <Provider value={{ displayLoader, setDisplayLoader }}>{children}</Provider>
  )
}

export default AppLoaderContextProvider
