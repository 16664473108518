import { createContext, Dispatch, SetStateAction, useState } from 'react'

interface FeedBackContext {
  displayFeedback: boolean
  setDisplayFeedback: Dispatch<SetStateAction<boolean>>
}
export const FeedBackContext = createContext<FeedBackContext>({
  displayFeedback: false,
  setDisplayFeedback: () => null
})

const { Provider } = FeedBackContext

function FeedBackContextProvider({ children }: { children: React.ReactNode }) {
  const [displayFeedback, setDisplayFeedback] = useState(false)

  return (
    <Provider value={{ displayFeedback, setDisplayFeedback }}>
      {children}
    </Provider>
  )
}

export default FeedBackContextProvider
