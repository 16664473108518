function Logo() {
  return (
    <svg
      width="67"
      height="41"
      viewBox="0 0 67 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.1541 40H27.9772L21.2291 25.337C19.1623 26.4614 17.5933 28.4115 15.9548 30.4478C14.6729 32.0411 13.3484 33.6872 11.7099 35.0318C9.80282 36.596 7.58603 37.981 4.81505 38.9261C3.55995 39.3497 1.42466 39.9037 0.625968 39.9852C0.28367 40.0178 0.0554709 40.0015 0.00657117 39.8874C-0.0260287 39.7896 0.0554709 39.7245 0.414069 39.5452C0.486586 39.5104 0.599365 39.4597 0.741752 39.3955C1.39959 39.0993 2.68915 38.5185 3.55995 37.9158C4.88025 36.9871 5.63004 35.9932 5.69524 35.2274C5.6287 34.9915 5.48527 34.7018 5.31265 34.3532C4.55498 32.8229 3.23494 30.1568 5.38554 25.9236C5.76552 25.1704 6.12444 24.5042 6.45594 23.8888C6.94921 22.9731 7.38178 22.1701 7.73273 21.3613C8.40103 19.8134 8.89003 17.63 9.05303 16.0006C9.06933 15.9028 9.08563 15.9028 9.13453 15.968C10.4222 17.2715 15.41 22.3878 14.6765 27.4878C16.3391 26.8523 19.2079 24.9133 20.5282 23.8868C20.8384 23.6452 21.1247 23.402 21.3985 23.1694C22.3619 22.351 23.171 21.6636 24.326 21.6383C25.068 21.6291 25.4291 21.8258 25.791 22.023C26.0732 22.1767 26.3559 22.3307 26.8199 22.3878C27.0807 22.4204 27.4719 22.3715 27.6675 22.2737C27.749 22.2411 27.7327 22.1434 27.6023 22.1108C27.0055 21.9684 26.5949 21.6822 26.185 21.3964C25.6099 20.9954 25.0362 20.5955 23.9512 20.5955C22.7003 20.5955 21.6339 21.4624 20.7729 22.1625C20.4896 22.3929 20.2284 22.6052 19.9903 22.7625L15.2796 12.9536C13.3236 8.84757 13.9104 5.8658 18.2462 0L37.1541 40Z"
        fill="white"
      />
      <path
        d="M52.1328 40.0164H46.4442C44.0481 40.0164 42.8256 39.4866 41.9454 38.3111C41 37.0693 41 35.1654 41 33.3773V0.0163574H48.335V34.4534C48.335 37.1852 48.6283 39.1223 52.1328 39.9005V40.0164Z"
        fill="white"
      />
      <path
        d="M60.4442 40.0164H66.1328V39.9005C62.6284 39.1223 62.335 37.1852 62.335 34.4534V0.0163574H55V33.3773C55 35.1654 55 37.0693 55.9454 38.3111C56.8256 39.4866 58.0481 40.0164 60.4442 40.0164Z"
        fill="white"
      />
    </svg>
  )
}

export default Logo
