import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root {
    --bg-color: #050033F2;
    --btn-hover-bg-color: #6F6E77;
    --primary-color: #FFFFFF;
    --secondary-color: #544E85;
    --text-secondary-color: #6F6E77;
    --btn-border-color: var(--secondary-color);
    --btn-hover-color: #38326c;
    --fonts-color-contextual-question: var(--primary-color);
    
    --fonts-size-contextual-question: 1rem;
    --fonts-weight-contextual-question: 400;
    --fonts-line-height-contextual-question: 1.5rem;
    --fonts-size-contextual-question-mobile: .875rem;

    --fonts-size-message-header: 0.75rem;
    --fonts-weight-message-header: 400;
    --fonts-line-height-message-header: 1.5rem;
    
    --fonts-size-message: 1rem;
    --fonts-weight-message: 400;
    --fonts-line-height-message: 1.5rem;

    --fonts-size-message-mobile: 1rem;
    --fonts-line-height-message-mobile: 1.5rem;

    --fonts-size-h1: 1.875rem;

    --backgournd-color-thinking-ai: #C9C7DE;
    --bg-color-page-header: rgba(5, 0, 51, 0.5);

    --ai-message-pandding-left: 2.125rem;

    --ai-welcome-message-first-line-font-size: 2.125rem;
    --ai-welcome-message-first-line-line-height: 1.75em;
    --ai-welcome-message-second-and-last-line-font-size: 1rem;
    --ai-welcome-message-second-and-last-line-font-height: 1.5em;
    
    
    --user-message-desktop-padding-left: 2.125rem;
    --user-message-mobile-padding-right: 2.125rem;

    --user-question-background-color: #007576;
    --ai-response-background-color: #1e1852;

    --user-question-padding: 1rem;

    --messages-border-radius: 8px;
    --link-color: #ffffff;

    
  }

  @font-face {
    font-family: 'Roboto', sans-serif;
    src: url('Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roboto', sans-serif;
    src: url('Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: bold;
  }
  
  body {
    margin: 0px;
    ::-webkit-scrollbar {
        display: none;
    }
    
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: var(--bg-color);
  }

  h1{
    margin: 0px;
  }
 
  button {
    all: unset;
    text-align: center;
    cursor: pointer;
  }

  textarea {
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
  }
`

export default GlobalStyles
